@keyframes commandbar-loader-animation {
  to {
    transform: rotate(360deg);
  }
}

.commandbar-loader:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  right: 20px;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  /* border: 2px solid;
  border-top-color: #000; */
  animation: commandbar-loader-animation 0.6s linear infinite;
}
