.poweredContainer {
  display: flex;
  align-items: center;
  opacity: 0.5;
  text-decoration: none;
  cursor: pointer;
}

.poweredContainer:hover {
  opacity: 1;
}
