.commandbar-search-filter-breadcrumb,
.commandbar-breadcrumb,
.commandbar-breadcrumb-background-left,
.commandbar-breadcrumb-background-top {
  line-height: 19.2px;
  max-width: fit-content;
  min-width: 0px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  transition: color ease-in 0.3s;
  overflow: hidden;
  text-overflow: ellipsis;
}

.commandbar-search-filter-breadcrumb {
  padding: 4px;
}

.commandbar-breadcrumb-background-left {
  margin: 0px 4px 8px 4px;
  border-radius: 4px;
}

.commandbar-breadcrumb-background-top {
  margin: 4px;
  border-radius: 4px;
}

.commandbar-breadcrumbs-container-left {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -110px;
  max-width: 100px;
  z-index: -1;
  overflow: visible;
  align-items: flex-end;
  top: 0px;
}

.commandbar-breadcrumbs-container-top {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
}
