/*** Guidance banner styling and animation ***/
@keyframes example {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-30px);
  }
}
@-webkit-keyframes example {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-30px);
  }
}

@-moz-keyframes example {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-30px);
  }
}

.rc-header-guidance {
  animation: example 0.5s; /* IE 10+, Fx 29+ */
  -webkit-animation: example 0.5s; /* Safari 4+ */
  -moz-animation: example 0.5s; /* Fx 5+ */
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards; /* Safari 4+ */
  -moz-animation-fill-mode: forwards; /* Fx 5+ */
}

/*** CommandBar entry animation ***/
/*** We can't do exit animation because rc-dialog makes display: none ***/
@keyframes onEntry {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes onEntry {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes onEntry {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.commandbar-opened {
  animation: onEntry 0.1s; /* IE 10+, Fx 29+ */
  -webkit-animation: onEntry 0.1s; /* Safari 4+ */
  -moz-animation: onEntry 0.1s; /* Fx 5+ */
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards; /*   Safari 4+ */
  -moz-animation-fill-mode: forwards; /*  Fx 5+ */
}

@keyframes onSelect {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.99);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes onSelect {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.99);
  }
  100% {
    transform: scale(1);
  }
}

@-moz-keyframes onSelect {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.99);
  }
  100% {
    transform: scale(1);
  }
}

.commandbar-selected {
  animation: onSelect 0.4s; /* IE 10+, Fx 29+ */
  -webkit-animation: onSelect 0.4s; /* Safari 4+ */
  -moz-animation: onSelect 0.4s; /* Fx 5+ */
}

@keyframes ScaleEntry {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes ScaleEntry {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@-moz-keyframes ScaleEntry {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.commandbar-scale-entry-animation {
  animation: ScaleEntry 0.3s; /* IE 10+, Fx 29+ */
  -webkit-animation: ScaleEntry 0.3s; /* Safari 4+ */
  -moz-animation: ScaleEntry 0.3s; /* Fx 5+ */
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards; /*   Safari 4+ */
  -moz-animation-fill-mode: forwards; /*  Fx 5+ */
}

.commandbar-shortcut-tooltip {
  opacity: 0;
  animation: tagEntry 0.2s;
  animation-delay: 0.5s;
  -webkit-animation: tagEntry 0.2s;
  -webkit-animation-delay: 0.5s;
  -moz-animation: tagEntry 0.2s;
  -moz-animation-delay: 0.5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
}

@keyframes tagExit {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.8);
    opacity: 0;
  }
}

@-webkit-keyframes tagExit {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.8);
    opacity: 0;
  }
}

@-moz-keyframes tagExit {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.8);
    opacity: 0;
  }
}

.commandbar-shortcut-tooltip-remove {
  animation: tagExit 0.2s; /* IE 10+, Fx 29+ */
  -webkit-animation: tagExit 0.2s; /* Safari 4+ */
  -moz-animation: tagExit 0.2s; /* Fx 5+ */
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards; /*   Safari 4+ */
  -moz-animation-fill-mode: forwards; /*  Fx 5+ */
}

.commandbar-shortcut-tooltip,
.commandbar-shortcut-tooltip-remove {
  z-index: 99999999;
  position: absolute;
  padding: 8px 12px;
  background-color: rgba(0, 0, 0, 0.8);
  font-family: proxima-nova, sans-serif;
  color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  font-size: 13px;
}

.commandbar-shortcut-tooltip-tag {
  margin-left: 10px;
  padding: 2px 5px;
  border-radius: 2px;
  background-color: grey;
}

#commandbar-launcher {
  animation: 0.25s ease-out scaleIn;
}

@-webkit-keyframes scaleIn {
  0% {
    -webkit-transform: scale(0.06);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@keyframes scaleIn {
  0% {
    transform: scale(0.06);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes growFromBottom {
  0% {
    -webkit-transform: scale(1, 0.3);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}
@keyframes growFromBottom {
  0% {
    transform: scale(1, 0.3);
  }
  100% {
    transform: scale(1, 1);
  }
}

.commandbar-animation-fade-in {
  animation: 0.3s ease-out onEntry;
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

@-webkit-keyframes moveLeft {
  0% {
    left: 0;
  }
  100% {
    left: -110;
  }
}
@keyframes moveLeft {
  0% {
    left: 0;
  }
  100% {
    left: -110;
  }
}

.commandbar-breadcrumbs-container {
  animation: moveLeft 0.4s; /* IE 10+, Fx 29+ */
  -webkit-animation: moveLeft 0.4s; /* Safari 4+ */
  -moz-animation: moveLeft 0.4s; /* Fx 5+ */
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards; /*   Safari 4+ */
  -moz-animation-fill-mode: forwards; /*  Fx 5+ */
}
