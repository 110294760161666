/* Copyright 2013 The Chromium Authors. All rights reserved.
 * Use of this source code is governed by a BSD-style license that can be
 * found in the LICENSE file. */

.commandbar-game {
  height: 160px;
}

.commandbar-game-icon {
  -webkit-user-select: none;
  user-select: none;
  display: inline-block;
}

.commandbar-game-icon-offline {
  content: -webkit-image-set(url(../../img/100-error-offline.png) 1x, url(../../img/200-error-offline.png) 2x);
  position: relative;
}

.commandbar-game-hidden {
  display: none;
}

/* Offline page */

.commandbar-game-offline .commandbar-game-interstitial-wrapper {
  color: #2b2b2b;
  font-size: 1em;
  line-height: 1.55;
  margin: 0 auto;
  max-width: 600px;
  padding-top: 100px;
  width: 100%;
}

.commandbar-game-offline .commandbar-game-runner-container {
  height: 150px;
  max-width: 10px;
  overflow: visible;
  position: relative;
  top: -450px;
  width: 44px;
  z-index: 2147483647 !important;
}

.commandbar-game-offline .commandbar-game-runner-canvas {
  height: 150px;
  max-width: 600px;
  opacity: 1;
  overflow: visible;
  top: 215px;
  position: absolute;
  z-index: 2147483647 !important;
}

.commandbar-game-offline .commandbar-game-controller {
  background: rgba(247, 247, 247, 0.1);
  height: 100vh;
  left: 0;
  position: absolute;
  top: 250px;
  width: 100vw;
  z-index: 2147483646 !important;
}

#commandbar-game-offline-resources {
  display: none;
}

@media (max-width: 420px) {
  .commandbar-game-suggested-left > #control-buttons,
  .commandbar-game-suggested-right > #control-buttons {
    float: none;
  }
  .commandbar-game-snackbar {
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0;
  }
}

@media (max-height: 350px) {
  h1 {
    margin: 0 0 15px;
  }
  .commandbar-game-icon-offline {
    margin: 0 0 10px;
  }
  .commandbar-game-interstitial-wrapper {
    margin-top: 5%;
  }
  .commandbar-game-nav-wrapper {
    margin-top: 30px;
  }
}

@media (min-width: 600px) and (max-width: 736px) and (orientation: landscape) {
  .commandbar-game-offline .commandbar-game-interstitial-wrapper {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 420px) and (max-width: 736px) and (min-height: 240px) and (max-height: 420px) and (orientation: landscape) {
  .commandbar-game-interstitial-wrapper {
    margin-bottom: 0px;
  }
}

@media (min-height: 240px) and (orientation: landscape) {
  .commandbar-game-offline .commandbar-game-interstitial-wrapper {
    margin-bottom: 0px;
  }
  .commandbar-game-icon-offline {
    margin-bottom: 20px;
  }
}

@media (max-height: 320px) and (orientation: landscape) {
  .commandbar-game-icon-offline {
    margin-bottom: 0;
  }
  .commandbar-game-offline .commandbar-game-runner-container {
    top: 260px;
  }
}

@media (max-width: 240px) {
  .commandbar-game-interstitial-wrapper {
    overflow: inherit;
    padding: 0 8px;
  }
}
